<template>
  <div>
    <el-dialog :title="text+'代理商'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="100px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="代理商名称"
                          prop="agent_name">
              <el-input placeholder="请输入代理商名称"
                        v-model="form.agent_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="上级代理商">
              <el-cascader v-model="form.pid"
                           style="width:100%"
                           clearable
                           filterable
                           :options="tree"
                           :props="props"
                           :show-all-levels="false"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="学校">
              <el-select v-model="form.school_id"
                         clearable
                         multiple
                         filterable
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in schoolList"
                           :key="item.school_id"
                           :label="item.school_name"
                           :value="item.school_id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input placeholder="请输入描述"
                        v-model="form.desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        agent_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      },
      checkList: [],
      schoolList: [],
      text: '',
      tree: [],
      props: {
        emitPath: false,
        label: 'agent_name',
        value: 'agent_id',
        children: 'child',
        checkStrictly: true
      }
    }
  },
  mounted () {
    this.getSchoolList()
  },
  methods: {
    getSchoolList () {
      this.$http({
        url: '/api/v2/public/enabled_school',
        method: 'get'
      }).then(res => {

        this.schoolList = res.data.list
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    getTree () {
      this.$http({
        url: '/api/v2/bureau/agent_tree',
        method: 'get'
      }).then(res => {
        this.tree = res.data
      })
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v2/bureau/agent_create',
          method: 'post',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })

    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>