<template>
  <div>
    <el-dialog title="添加教师"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="100px">

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="教师">
              <el-select v-model="form.edu_user_id"
                         clearable
                         filterable
                         multiple
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in teacherList"
                           :key="item.edu_user_id"
                           :label="item.edu_user_name"
                           :value="item.edu_user_id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      teacherList: [],
    }
  },
  methods: {
    getTeacherList (agent_id) {
      this.$http({
        url: '/api/v2/bureau/agent_op_tc',
        method: 'get',
        params: {
          agent_id
        }
      }).then(res => {
        this.teacherList = res.data
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v2/bureau/agent_eu_create',
          method: 'post',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>