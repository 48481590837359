<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="6">
        <el-input clearable
                  style="width:100%"
                  v-model="keyword"
                  placeholder="请输入代理商名称"
                  @keyup.enter.native="search"></el-input>
      </el-col>
      <el-col :span="15">
        <el-button type="primary"
                   @click="search">确定</el-button>
        <el-button type="primary"
                   @click="addStudent">新增</el-button>
      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <AddTeacher ref="AddTeacherRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <LookForm ref="LookFormRef" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import LookForm from './components/look.vue'
import AddTeacher from './components/addTeacher.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      keyword: '',
      tableData: [],
      showList: [],
      pageName: 'agent_admin',
      tableColumns: [
        { prop: 'agent_id', align: 'center', label: '编号', width: '', showToopic: false },
        { prop: 'agent_name', align: 'center', label: '名称', width: '', showToopic: false },
        { prop: 'desc', align: 'center', label: '描述', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '查看', type: 'look', event: 'look' },
            { content: '添加老师', type: 'teacher', event: 'addTeacher' },
            { content: '修改', type: 'edit', event: 'showForm' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Form, LookForm, AddTeacher
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    addTeacher (row) {
      let form = {
        agent_id: row.agent_id,
        edu_user_id: row.teachers ? row.teachers.split(',').map(item => item - 0) : []
      }
      this.$refs.AddTeacherRef.form = form
      this.$refs.AddTeacherRef.getTeacherList(row.agent_id)
      this.$refs.AddTeacherRef.dialogVisible = true
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.$http({
        url: '/api/v2/bureau/agent_lst',
        method: 'get',
        params: {
          page,
          limit,
          keyword: this.keyword
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/bureau/agent_del',
          method: 'post',
          data: {
            agent_id: row.agent_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addStudent () {
      let form = {
        agent_name: '',
        desc: '',
        pid: '',
        school_id: [],
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getTree()
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.dialogVisible = true
    },
    getAgentSchoolList (agent_id) {
      return this.$http({
        url: '/api/v2/bureau/agent_school',
        method: 'get',
        params: {
          agent_id
        }
      })
    },
    async showForm (row) {
      let form = JSON.parse(JSON.stringify(row))
      const { data: list } = await this.getAgentSchoolList(row.agent_id)
      form.school_id = list.map(item => item.school_id)
      this.$refs.FormRef.getTree()
      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    async look (row) {
      const { data } = await this.getAgentSchoolList(row.agent_id)
      this.$refs.LookFormRef.list = data
      this.$refs.LookFormRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
